import { GatsbyFixedImageProps, GatsbyFluidImageProps } from "gatsby-source-sanity"

export enum text_marks {
  strong = "strong",
  em = "em",
  italic = "italic",
  code = "code",
}

export type AllGraphQLResponse<T> = {
  edges: {
    node: T
  }[]
}

export type Marker = {
  location: { lat: number; lng: number; alt: number }
  _rawMarkerText: SanityRawText[]
}

export type SEO = {
  title?: string
  description?: string
  lang?: string
  location: string
  meta?: any[]
  image?: string
  keywords?: string
  twitterCard?: string
  twitterSite?: string
  twitterCreator?: string
}

export type RichText = {
  text: string
  marks: text_marks[]
  _key: string
  _type: string
}

export type Slug = {
  current: string
}

export type Link = {
  title: string
  slug: Slug
}

export type NavigationLink = {
  title: string
  slug: Slug
  childs: Link[]
  showInFooter: boolean
  showInHeader: boolean
}

export enum cta_button_types {
  primary = "Primary",
  secondary = "Secondary",
  tertiary = "Tertiary",
  text = "Text",
  navigation = "Navigation",
}

export type CtaButton = {
  title: string
  linkUrl: string
  buttonType?: cta_button_types
}

export type NavigationType = {
  title: string
  content: NavigationLink[]
  ctaButton: CtaButton
  _rawContactInfo: SanityRawText[]
}

export type SanityRawText = {
  _key: string
  _type: string
  children: RichText[]
  marksDef: any[]
  style: string
}

export enum content_types {
  carousel = "carousel",
  simpleSection = "simpleSection",
  contentSection = "contentSection",
  middleSectionImage = "middleSectionImage",
  mainImage = "mainImage",
  heroWithCta = "heroWithCta",
}

export type SanityImage = {
  alt: string
  asset: {
    fluid: GatsbyFluidImageProps
    fixed: GatsbyFixedImageProps
  }
}

export type SanityCarouselItem = {
  _rawTitle: SanityRawText[]
  image: SanityImage
}

export type SanityCarousel = {
  id: string
  _type: string
  title: string
  content: SanityCarouselItem[]
}

export type SanitySimpleSection = {
  id: string
  _type: string
  title: string
  _rawContent: SanityRawText[]
  backgroundColor: string
}

export type SanitySimpleSectionNoTitle = {
  id: string
  _type: string
  _rawContent: SanityRawText[]
  backgroundColor: string
  backgroundImage: SanityImage
}

export type SanityContentSection = {
  id: string
  _type: string
  title: string
  type: string
  date: string
  content: ({ _rawContent: SanityRawText[] } | SanityBlogPost)[]
  backgroundColor: string
  ctaButton: CtaButton[]
}

export type SanityHeroWithCta = {
  _type: string
  arrowUpIcon: boolean
  ctaButtons: CtaButton[]
  backgroundColor: string
  _rawContent: SanityRawText[]
  backgroundImage: SanityImage
  image: SanityImage
}

export type SanityMainImage = {
  _type: string
  alt: string
  asset: {
    fluid: GatsbyFluidImageProps
    fixed: GatsbyFixedImageProps
  }
  hotspot: {
    height: number
    width: number
    y: number
    x: number
  }
}

export type FormButton = {
  title: string
  linkUrl: string
  buttonType?: "Submit" | "Reset" | "Cancel"
  buttonStyle: cta_button_types
}

export type SanityForm = {
  _type: string
  title: string
  formTitle: SanityRawText[]
  form: {
    name: string
    required: boolean
    type: string
    textArea: boolean
  }[]

  information: SanityRawText[]
  buttons: FormButton[]
}

export type SanityMiddleSectionImage = {
  _key: string
  _type: string
  alt: string
  asset: {
    fluid: GatsbyFluidImageProps
    fixed: GatsbyFixedImageProps
  }
}

export type SanityHeroTextImage = {
  _rawTitle: SanityRawText[]
  sectionName: string
  image: SanityImage
  backgroundColor: string
  backgroundImage: SanityImage
}

export type SanityBlocksGridSection = {
  name: string
  _type: string
  _rawTitle: SanityRawText[]
  backgroundColor: string
  backgroundImage: SanityImage
  blocks: { _rawContent: SanityRawText[] }[]
}

export type SanityImageWithLeafs = {
  name: string
  _type: string
  image: SanityImage
  imageAlign: "left" | "center" | "rigth"
  hasLeafs: boolean
  backgroundColor: string
  title: string
  _rawContent: SanityRawText[]
  classes: string
}

export type SanityEmployee = {
  name: string
  _rawCardName: SanityRawText[]
  positions: string[]
  image: SanityImage
  _rawContact: SanityRawText[]
  _rawBio: SanityRawText[]
}

export type SanitySectionWithEmployees = {
  name: string
  _type: string
  backgroundColor: string
  image: SanityImage
  _rawTitle: SanityRawText[]
  employees: SanityEmployee[]
}

export type SanitySectionWithLeafs = {
  name: string
  _type: string
  backgroundColor: string
  classes: string
  leafsAlign: "left" | "center" | "rigth"
  title: string
  _rawContent: SanityRawText[]
}

export type SanityHeroWithBlocks = {
  name: string
  _type: string
  backgroundColor: string
  backgroundImage: SanityImage
  titleWithImage: SanityCarouselItem
  blockSubtitle: string
  blockTitle: string
  blocks: { _rawContent: SanityRawText[] }[]
  classes: string
}

export const ContentTypes = {
  SanityBlocksGridSection: "blocksGridSection",
  SanitySectionWithEmployees: "sectionWithEmployees",
  SanitySectionWithLeafs: "sectionWithLeafs",
  SanityImageWithLeafs: "imageWithLeafs",
  SanityHeroWithBlocks: "heroWithBlocks",
  SanityForm: "form",
  SanityImage: "mainImage",
}

export type SanityHomePage = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  carousel: SanityCarousel
  alignedContentSection: SanityContentSection //HERE
  unalignedContentSection: SanityContentSection
  heroWithCta: SanityHeroWithCta
  Image: SanityMainImage
  middleSectionImage: SanityMainImage
  simpleSection: SanitySimpleSection
}

export type SanityHowWeHelp = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  backgroundImage: SanityImage
  heroTextImage: SanityHeroTextImage
  middleSectionImage: SanityMainImage
  firstSection: SanitySimpleSectionNoTitle
  lastSection: SanitySimpleSectionNoTitle
}

export type SanityWhoWeAre = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  content: (SanityBlocksGridSection | SanityImageWithLeafs | SanitySectionWithEmployees | SanitySectionWithLeafs)[]
}

export type SanityWhoWeServe = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  content: (SanityHeroWithBlocks | SanityImageWithLeafs)[]
}

export type SanityClientAccess = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  content: CtaButton[]
}

export type SanityGetInTouch = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  _rawForm: SanityForm
  image: SanityMainImage
  address: string
  _rawContactInfo: SanityRawText[]
  blocks: { _rawContent: SanityRawText[] }[]
  markers: Marker[]
  map: SanityImage
  location: { lat: number; lng: number; alt: number }
}

export type File = {
  asset: {
    url: string
    originalFilename: string
  }
}

export type SanityBlogPost = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  _rawCategory: BlogCategory
  author: string
  date: string
  image: SanityMainImage
  _rawArticle: SanityRawText[]
  file: File
  tags: [{ tag: string }]
  featured: string
}

export type SanityDisclosure = {
  seo: SEO
  _type: string
  slug: Slug
  title: string
  backgroundColor: string
  _rawContent: SanityRawText[]
  relationshipSummary: {asset: {url: string}}
  disclosureStatement: {asset: {url: string}}
  privacyPolicy: {asset: {url: string}}
}

export type SanityBlog = {
  seo: SEO
  _type: string
  _rawBody: SanityRawText[]
  slug: Slug
  title: string
  backgroundColor: string
  featured: string
}

export type BlogCategory = {
  category: string
  _rawBody: SanityRawText[]
}

export type BlogTag = {
  tag: string
}
