import React from "react"
import "./index.scss"
import { SanityImageWithLeafs } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import Leafs from "@components/Leafs"
import Image from "gatsby-image"

type Props = {
  sanityImageWithLeafs: SanityImageWithLeafs
  classes?: string
  blockClass?: string
}

const SectionImageWithLeafs = ({
  sanityImageWithLeafs,
  classes,
  blockClass = "siwl-text-content",
}: Props) => {
  return (
    <section
      id={sanityImageWithLeafs?.name}
      className={`${sanityImageWithLeafs.classes} ${classes}`}
      style={{
        backgroundColor: sanityImageWithLeafs.backgroundColor || "transparent",
      }}
    >
      {sanityImageWithLeafs.hasLeafs && (
        <Leafs
          classes={`siwl-leafs-image-${sanityImageWithLeafs.imageAlign}`}
        />
      )}
      {sanityImageWithLeafs.image.asset && (
        <Image
          className={`siwl-image siwl-image-${sanityImageWithLeafs.imageAlign}`}
          fluid={sanityImageWithLeafs.image.asset.fluid}
          alt={sanityImageWithLeafs.image.alt}
        />
      )}
      <div className="siwl-text-content-container">
        {sanityImageWithLeafs.title && (
          <h2 className="siwl-title">{sanityImageWithLeafs.title}</h2>
        )}
        <div className={blockClass}>
          <BaseBlockContent
            blocks={sanityImageWithLeafs._rawContent}
            className={`siwl-wrapper siwl-block-padding`}
          />
        </div>
      </div>
    </section>
  )
}

export default SectionImageWithLeafs
