import React, { useState } from "react"
import "./index.scss"
import { SanitySectionWithEmployees } from "@utils/globalTypes"
import { Row, Col } from "react-bootstrap"
import { BaseBlockContent } from "@utils/richText"
import Image from "gatsby-image"
import EmployeeCard from "@components/Employee/Card"
import EmployeeModal from "@components/Employee/Modal"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  sanitySectionWithEmployees: SanitySectionWithEmployees
  classes?: string
}

const SectionWithEmployees = ({
  sanitySectionWithEmployees,
  classes,
}: Props) => {
  const [show, setShow] = useState(false)
  const [currentEmployee, setCurrentEmployee] = useState({})
  const { width } = useResizeWidth()

  const showEmployeeDetails = (employee) => {
    setCurrentEmployee(employee)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const renderContent = () => (
    <div>
      <div className="es-padding-right employee-section-image-container">
        {sanitySectionWithEmployees?.image?.asset && (
          <Image
            fluid={sanitySectionWithEmployees.image.asset.fluid}
            alt={sanitySectionWithEmployees.image.alt}
            className="employee-section-image"
          />
        )}
      </div>
      <section
        id={sanitySectionWithEmployees?.name}
        className="employee-section-container"
      >
        <div className="es-padding-right">
          <div>
            <Col className="employee-section-title">
              <BaseBlockContent blocks={sanitySectionWithEmployees._rawTitle} />
            </Col>
          </div>
        </div>
        <Row
          className="employee-card-container es-cards-padding-rigth"
          noGutters
        >
          {sanitySectionWithEmployees.employees.map((employee, index) => (
            <div
              key={index}
              className={`employee-card  ${
                index % 2
                  ? width > 550
                    ? "margin-left"
                    : ""
                  : width > 550
                  ? "inverse-margin-top-card"
                  : ""
              }`}
            >
              <EmployeeCard
                employee={employee}
                showEmployeeDetails={showEmployeeDetails}
              />
            </div>
          ))}
        </Row>
      </section>
      <EmployeeModal
        employee={currentEmployee}
        show={show}
        handleClose={handleClose}
      />
    </div>
  )

  return (
    <section
      className={`${classes}`}
      style={
        sanitySectionWithEmployees?.backgroundColor
          ? { backgroundColor: sanitySectionWithEmployees.backgroundColor }
          : {}
      }
    >
      {renderContent()}
    </section>
  )
}

export default SectionWithEmployees
