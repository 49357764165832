import React from "react"
import "./index.scss"
import { Image } from "react-bootstrap"
import LeafIcon from "@assets/svg/Vertical_Logo_icon_opacity.svg"

type Props = {
  classes?: string
  style?: any
}

const Leafs = ({ classes = "", style = {} }) => {
  return (
    <div className={classes} style={style}>
      <div className="leaf-line">
        <Image id="leaf-1" src={LeafIcon} alt="birchbrook" />
        <Image id="leaf-2" src={LeafIcon} alt="birchbrook" />
      </div>
      <div className="leaf-line">
        <Image id="leaf-3" src={LeafIcon} alt="birchbrook" />
        <Image id="leaf-4" src={LeafIcon} alt="birchbrook" />
      </div>
    </div>
  )
}

export default Leafs
