import React, { useEffect } from "react"
import "./index.scss"
import SEO from "@components/SEO"
import {
  SanityWhoWeAre,
  NavigationType,
  ContentTypes,
  SanityBlocksGridSection,
  SanitySectionWithEmployees,
  SanitySectionWithLeafs,
  SanityImageWithLeafs,
} from "@utils/globalTypes"
import LateralNavigation from "@components/LateralNavigation"
import useResizeWidth from "@hooks/useResizeWidth"
import { graphql } from "gatsby"
import GridBlocksSection from "@components/GridBlocksSection"
import SectionWithEmployees from "@components/EmployeesSection"
import SectionWithLeafs from "@components/SectionWithLeafs"
import SectionImageWithLeafs from "@components/SectionImageWithLeafs"
import ScrollUpButton from "@components/ScrollUpButton"
import { Row } from "react-bootstrap"

type Props = {
  data: {
    sanityWhoWeAre: SanityWhoWeAre
    sanityNavigation: NavigationType
  }
  location: Location
}

const WhoWeArePage = ({
  data: { sanityWhoWeAre, sanityNavigation },
  location,
}: Props) => {
  const { title, backgroundColor, content } = sanityWhoWeAre
  const links = sanityNavigation?.content?.find(
    (item) => item?.slug?.current === `/${location.pathname.split("/")[1]}`
  )
  const { width } = useResizeWidth()
  const metadata = {
    location: location.href,
    ...sanityWhoWeAre?.seo,
  }

  return (
    <div
      className="wwa parent-padding"
      style={{
        backgroundColor:
          content[0].backgroundColor || backgroundColor || "transparent",
      }}
    >
      <SEO {...metadata} />
      {width < 960 ? (
        <div className="lateral-mobile-nav">
          <LateralNavigation links={links} slug={sanityWhoWeAre.slug} />
        </div>
      ) : (
        <LateralNavigation links={links} slug={sanityWhoWeAre.slug} />
      )}
      <section className="ww-section-wrapper">
        {content &&
          content.map((section, index) => {
            switch (section._type) {
              case ContentTypes.SanityBlocksGridSection:
                const sanityBlocksGridSection = section as SanityBlocksGridSection
                return (
                  <GridBlocksSection
                    key={index}
                    sanityBlocksGridSection={sanityBlocksGridSection}
                    classes={`wwa-padding-left ${
                      index > 0
                        ? "wwa-container wwa-container-padding-bottom"
                        : "wwa-first-section  content-padding-top"
                    }`}
                  />
                )
              case ContentTypes.SanityImageWithLeafs:
                const sanityImageWithLeafs = section as SanityImageWithLeafs
                return (
                  <SectionImageWithLeafs
                    key={index}
                    sanityImageWithLeafs={sanityImageWithLeafs}
                    classes={`wwa-image-with-leaf-text wwa-padding-left wwa-padding-rigth ${
                      index > 0
                        ? "wwa-container wwa-container-padding-bottom"
                        : "wwa-first-section content-padding-top"
                    } ${
                      sanityImageWithLeafs?.classes?.includes(
                        "section-with-image-bottom"
                      )
                        ? ""
                        : "wwa-container-padding-bottom"
                    }`}
                  />
                )
              case ContentTypes.SanitySectionWithEmployees:
                const sanitySectionWithEmployees = section as SanitySectionWithEmployees
                return (
                  <SectionWithEmployees
                    key={index}
                    sanitySectionWithEmployees={sanitySectionWithEmployees}
                    classes={`wwa-padding-left wwa-employees-section`}
                  />
                )
              case ContentTypes.SanitySectionWithLeafs:
                const sanitySectionWithLeafs = section as SanitySectionWithLeafs
                return (
                  <SectionWithLeafs
                    key={index}
                    sanitySectionWithLeafs={sanitySectionWithLeafs}
                    classes={`grids-blocks-sections ${
                      index > 0
                        ? "wwa-container"
                        : "wwa-first-section content-padding-top"
                    } ${
                      sanitySectionWithLeafs?.classes?.includes(
                        "section-with-image-bottom"
                      )
                        ? ""
                        : "wwa-container-padding-bottom"
                    }`}
                  />
                )
              default:
                break
            }
          })}
        {width > 640 ? (
          <ScrollUpButton type="dark" />
        ) : (
          <Row
            className="hwh-backTop-container"
            style={{
              backgroundColor:
                content[content.length - 1]?.backgroundColor || `transparent`,
            }}
          >
            {" "}
            <ScrollUpButton
              type="dark"
              style={{
                position: "relative",
                opacity: 1,
                right: "initial",
                bottom: "initial",
              }}
            />{" "}
          </Row>
        )}
      </section>
    </div>
  )
}

export default WhoWeArePage

export const query = graphql`
  query sanityWhoWeAre($slug: String) {
    sanityWhoWeAre(slug: { current: { eq: $slug } }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      slug {
        current
      }
      backgroundColor
      content {
        ... on SanityBlocksGridSection {
          name
          _type
          _rawTitle(resolveReferences: { maxDepth: 10 })
          backgroundColor
          backgroundImage {
            alt
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          blocks {
            _rawContent(resolveReferences: { maxDepth: 10 })
          }
        }
        ... on SanityImageWithLeafs {
          name
          _type
          image {
            alt
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageAlign
          hasLeafs
          backgroundColor
          title
          _rawContent(resolveReferences: { maxDepth: 10 })
          classes
        }
        ... on SanitySectionWithEmployees {
          name
          backgroundColor
          image {
            alt
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          _type
          _rawTitle(resolveReferences: { maxDepth: 10 })
          employees {
            name
            _rawCardName(resolveReferences: { maxDepth: 10 })
            positions
            image {
              alt
              asset {
                fluid(maxWidth: 10000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            _rawContact(resolveReferences: { maxDepth: 10 })
            _rawBio(resolveReferences: { maxDepth: 10 })
          }
        }
        ... on SanitySectionWithLeafs {
          name
          _type
          backgroundColor
          classes
          leafsAlign
          title
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
    sanityNavigation {
      content {
        title
        slug {
          current
        }
        childs {
          title
          slug {
            current
          }
        }
      }
    }
  }
`
