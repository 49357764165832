import React from "react"
import "./index.scss"
import { SanityBlocksGridSection } from "@utils/globalTypes"
import { Row, Col, Button } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import { BaseBlockContent } from "@utils/richText"
import useResizeWidth from "@hooks/useResizeWidth"
import { useState } from "react"

type Props = {
  sanityBlocksGridSection: SanityBlocksGridSection
  classes?: string
  id?: string
}

const GridBlocksSection = ({
  sanityBlocksGridSection,
  classes = "",
  id = "",
}: Props) => {
  const { width } = useResizeWidth()
  const [expanded, setExpanded] = useState(false)
  const renderContent = () => (
    <Row className="block-grids">
      <Col xs={12} className="grid-block-title">
        {" "}
        <BaseBlockContent blocks={sanityBlocksGridSection._rawTitle} />
      </Col>
      <Row className="grid-block-grid">
        {sanityBlocksGridSection.blocks.map(({ _rawContent }, index) => (
          <Col
            xs={12}
            lg={6}
            key={index}
            className={`${
              width < 960 && expanded
                ? "grid-text-margin"
                : index > 0
                ? "grid-expanded-hidden grid-text-margin"
                : "grid-expanded-truncated grid-text-margin"
            }`}
          >
            <BaseBlockContent blocks={_rawContent} />
          </Col>
        ))}
        {width < 960 && (
          <a
            rel="noopener"
            onClick={() => setExpanded(!expanded)}
            className="expand-grid-button"
          >
            {" "}
            {expanded ? "Read less" : "Read more"}{" "}
          </a>
        )}
      </Row>
    </Row>
  )

  return sanityBlocksGridSection.backgroundImage ? (
    <BackgroundImage
      id={sanityBlocksGridSection?.name}
      Tag={`section`}
      fluid={sanityBlocksGridSection?.backgroundImage?.asset?.fluid}
      backgroundColor={
        sanityBlocksGridSection?.backgroundColor || "transparent"
      }
      alt={sanityBlocksGridSection?.backgroundImage?.alt || "birchbrook"}
      style={{
        backgroundPosition: width > 960 ? "70% center" : "top",
        backgroundSize:
          width > 960 ? (width > 1120 ? "80%" : "90%") : "contain",
        opacity: 1,
      }}
      className={`${classes}`}
    >
      {renderContent()}
    </BackgroundImage>
  ) : (
    <section
      id={sanityBlocksGridSection?.name}
      className={`${classes}`}
      style={
        sanityBlocksGridSection?.backgroundColor
          ? { backgroundColor: sanityBlocksGridSection.backgroundColor }
          : {}
      }
    >
      {renderContent()}
    </section>
  )
}

export default GridBlocksSection
