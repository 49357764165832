import React from "react"
import "./index.scss"
import { SanityEmployee } from "@utils/globalTypes"
import { Modal, Row, Col } from "react-bootstrap"
import Image from "gatsby-image"
import { BaseBlockContent } from "@utils/richText"

type Props = {
  employee: SanityEmployee | any
  show: boolean
  handleClose: () => void
}

const EmployeeModal = ({ employee, show, handleClose }: Props) => {
  return (
    <Modal show={show} onHide={() => handleClose()} className="employee-modal">
      <Modal.Header closeButton />
      <Modal.Body>
        <Row className="modal-personal-info phone-class" noGutters>
          <Col lg={6} sm={12} xs={12}>
            {employee._rawCardName ? (
              <BaseBlockContent blocks={employee._rawCardName} />
            ) : (
              <h2>{employee.name}</h2>
            )}
            <div id="positions">
              {employee?.positions?.map((pos, index) => (
                <p key={index}>{pos}</p>
              ))}
            </div>
            {employee?._rawContact && (
              <BaseBlockContent blocks={employee?._rawContact} />
            )}
          </Col>
          {employee?.image?.asset && (
            <Col lg={6} sm={12} xs={12}>
              <Image
                fluid={employee.image.asset.fluid}
                alt={employee.image.alt}
              />
            </Col>
          )}
        </Row>
        <Row className="modal-bio" noGutters>
          {employee?._rawBio && <BaseBlockContent blocks={employee?._rawBio} />}
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default EmployeeModal
