import React from "react"
import "./index.scss"
import { SanityEmployee } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import { Card } from "react-bootstrap"
import Image from "gatsby-image"

type Props = {
  employee: SanityEmployee
  showEmployeeDetails?: any
}

const EmployeeCard = ({ employee, showEmployeeDetails = {} }: Props) => {
  const handleOnClick = (e) => {
    e.preventDefault()
    showEmployeeDetails(employee)
  }

  return (
    <Card>
      <Card.Header as={"button"} onClick={handleOnClick}>
        <Image fluid={employee.image.asset.fluid} alt={employee.image.alt} />
      </Card.Header>
      <Card.Body>
        <button onClick={handleOnClick}>
          {employee._rawCardName ? (
            <BaseBlockContent blocks={employee._rawCardName} />
          ) : (
            <p>{employee.name}</p>
          )}
        </button>
        {employee.positions.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </Card.Body>
    </Card>
  )
}

export default EmployeeCard
