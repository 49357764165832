import React from "react"
import "./index.scss"
import { SanitySectionWithLeafs } from "@utils/globalTypes"
import Leafs from "@components/Leafs"
import { BaseBlockContent } from "@utils/richText"

type Props = {
  sanitySectionWithLeafs: SanitySectionWithLeafs
  classes?: string
}

const SectionWithLeafs = ({ sanitySectionWithLeafs, classes }: Props) => {
  return (
    <section
      id={sanitySectionWithLeafs?.name}
      className={`${classes} ${sanitySectionWithLeafs.classes}`}
      style={{
        backgroundColor:
          sanitySectionWithLeafs.backgroundColor || "transparent",
      }}
    >
      <Leafs
        classes={`swl-leafs swl-leafs-${sanitySectionWithLeafs.leafsAlign}`}
      />
      {sanitySectionWithLeafs.title && <h1>{sanitySectionWithLeafs.title}</h1>}
      <BaseBlockContent
        blocks={sanitySectionWithLeafs._rawContent}
        className="swl-wrapper swl-block-padding"
      />
    </section>
  )
}

export default SectionWithLeafs
